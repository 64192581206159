/*Dropdown Styles*/

.btn {
  background-color: darkgray;
  border: none;
  outline: nonel;

  &:focus {
    outline: none !important;
  }

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: black;
  }
}

.btn-primary[disabled] {
  background-color: darkgray;
  border: none;

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: darkgray;
  }
}

.navbar-brand.navbar-title {
  padding-top: 35px;
}

.center {
  text-align: center;
}

.flex {
  display: flex;
}

.visibility-none {
  visibility: hidden !important;
}

.save-submit {
  border: 1px solid #3cb878;
  color: #3cb878;
  transition: all 0.4s;
  float: right;
  margin-left: auto;

  &[disabled] {
    cursor: not-allowed;
  }

  @media screen and (min-width: 992px) {
    // hover only on desktop
    html.can-touch &:active:not([disabled]),
    html:not(.can-touch) &:hover:not([disabled]) {
      background-color: #3cb878;
      color: white;
    }
  }
}

.select-all-button {
  border: 1px solid #3cb878;
  color: #3cb878;
  transition: all 0.4s;
  float: right;
  margin-left: auto;

  @media screen and (min-width: 992px) {
    // hover only on desktop
    html.can-touch &:active:not([disabled]),
    html:not(.can-touch) &:hover:not([disabled]) {
      background-color: #3cb878;
      color: white;
    }
  }
}

.clear-all-button {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #fb5d5d;
  color: #fb5d5d;
  transition: all 0.4s;
  float: left;

  @media screen and (min-width: 992px) {
    // hover only on desktop
    html.can-touch &:active:not([disabled]),
    html:not(.can-touch) &:hover:not([disabled]) {
      background-color: #fb5d5d;
      color: white;
    }
  }
}

.panel .panel-footer {
  .btn[disabled] {
    color: #333;
  }
  .btn[disabled]:hover {
    background-color: inherit;
    opacity: 0.65;
  }
}

.cancel {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #fb5d5d;
  color: #fb5d5d;
  transition: all 0.4s;
  float: left;
  padding-left: 0 !important;

  @media screen and (min-width: 992px) {
    // hover only on desktop
    html.can-touch &:active:not([disabled]),
    html:not(.can-touch) &:hover:not([disabled]) {
      background-color: #fb5d5d;
      color: white;
    }
  }
}
.global-nav {
  display: flex;
  place-items: center;
  .ncr-menu-item {
    color: #0e66b4;
    &:hover {
      text-decoration-line: underline;
    }
  }
}
