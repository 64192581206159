@mixin save-button() {
  border: 1px solid #3cb878;
  color: #3cb878;
  transition: all 0.4s;
  float: right;
  margin-left: auto;

  &[disabled] {
    cursor: not-allowed;
    border-color: #dddddd;
    color: #333;

    html.can-touch &:active[disabled],
    html:not(.can-touch) &:hover[disabled] {
      background-color: transparent;
      border-color: #333;
    }
  }

  // hover only on desktop
  html.can-touch &:active:not([disabled]),
  html:not(.can-touch) &:hover:not([disabled]) {
    background-color: #3cb878;
    color: white;
  }
}

@mixin cancel-button() {
  border-radius: 4px;
  background-color: transparent;
  border: 1px solid #fb5d5d;
  color: #fb5d5d;
  transition: all 0.4s;
  float: left;

  // hover only on desktop
  html.can-touch &:active:not([disabled]),
  html:not(.can-touch) &:hover:not([disabled]) {
    background-color: #fb5d5d;
    color: white;
  }
}

@mixin disabled-button() {
  cursor: not-allowed;
  background-color: #dddddd !important;
}

// blue button with orange hover, used on timesheet
@mixin control-button() {
  background-color: #3264af;
  color: white;

  &:focus {
    color: white;
  }

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: #0a3b84;
    color: white;
  }
}

.total-days-worked {
  background-color: #636363;
  color: white;
  padding: 6px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  cursor: default; /* Remove pointer cursor */
}

/* Remove hover effects */
.total-days-worked:hover {
  background-color: #636363;
  cursor: default;
}

