.login {
  margin: auto;
  max-width: 500px;
  padding: 50px;
  position: fixed;
  top: 50%;
  left: 50%;
  font-family: 'Quicksand', sans-serif;
  transform: translate(-50%, -50%); //center

  @media (max-width: 991px) {
    margin: auto;
    max-width: 400px;
    padding: 20px;
    position: initial;
    transform: none;
  }

  .form-control {
    display: block;
    font-family: 'Quicksand', sans-serif;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .panel {
    margin-bottom: 5px;
  }

  .panel-body {
    padding: 0;
    position: relative;

    p {
      color: #999999;
      padding: 15px 14px 0 15px;
    }

    .password-first {
      padding: 15px 15px 0 15px;
    }

    .password-second {
      padding: 0px 15px 15px 15px;
    }

    // .btn-primary {
    //         background: transparent;
    //         outline: none;
    //         border: none;
    // }
  }

  // .btn-group {
  //         position: absolute;
  //         display: inline-block;
  //         vertical-align: middle;
  //         right: -50px;
  //         top: -9px;
  // }

  .form-group {
    margin: 0;
    padding: 15px;
  }

  .btn-group {
    display: block;
    padding: 15px;

    .btn {
      width: 100%;
      float: none;
    }
  }

  .form-control:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

div.logout {
  margin: auto;
  max-width: 500px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); //center

  @media (max-width: 991px) {
    margin: auto;
    max-width: 400px;
    margin-top: 20px;
    padding: 20px;
    position: initial;
    transform: none;
  }

  h4 {
    font-size: 16px;
    text-align: center;
  }

  .form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  .panel-body {
    padding: 15px;
    position: relative;
    text-align: center;
  }

  span {
    display: block;
    padding-bottom: 15px;
  }

  .form-group {
    margin: 0;
    padding: 15px;
  }

  .form-control:focus {
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  img.login-button {
    height: 131px;
  }
}

.logout,
.login {
  .btn {
    display: block;
    margin-top: 10px;
    background-color: #000;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #dd1f26;
    }
  }
}

a.forget-password {
  color: #999999;
  text-decoration: none;
  font-size: 13px;
}

.forgot {
  text-align: center;

  .form-group {
    padding: 0px !important;
  }

  h4 {
    font-size: 14px;
    text-align: left;
    padding: 10px 10px 0 10px;
  }
}

a.back-login {
  color: #999999;
  text-decoration: none;
  font-size: 13px;
}

.change-password {
  left: calc(50% + 75px) !important;
  .form-control {
    width: 300px !important;
    max-width: 100% !important;
  }
}
