.time-dialog {
  .panel-title {
    display: block;
    font-weight: 900;
  }

  .panel-footer {
    .btn.save-submit {
      @include save-button();
    }
  }

  .time-dialog-content {
    display: block;

    // .day-selections {
    //   display: flex;
    //   padding: 0 15px;
    // }
  }

  .time-dialog-heading {
    text-align: center;
    font-size: 15px;
  }

  .time-dialog-cells {
    display: flex;
    padding: 15px;

    .time-dialog-cell {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: center;

      .time-dialog-date {
        font-family: AvantGardeGothicITCW02Md;
      }

      &:not(.time-dialog-totals) {
        .time-dialog-cell-content,
        .time-dialog-cell-checkbox:not(:disabled) {
          cursor: pointer;
        }
      }

      // &:last-child .time-dialog-cell-content {
      //   border-right: 1px solid #8e8e8e;
      // }

      .time-dialog-cell-checkbox {
        left: 3px;
        margin: 0;
        position: absolute;
      }

      .time-dialog-cell-title {
        font-weight: bold;
        margin: 0;
        padding: 10px 0;
      }

      .time-dialog-cell-content {
        padding: 5px 0;
        position: relative;
        border-radius: 3px;
        margin: 5px;
        border: 1px solid #cdcdcd;

        &.time-dialog-cell-content--approved {
          background-color: #daf0e5 !important;
        }

        &.time-dialog-cell-content--submitted {
          background-color: #d7edf5 !important;
        }

        &.time-dialog-cell-content--posted {
          background-color: #e6e6e6 !important;
        }

        &.time-dialog-cell-content--read-only {
          background-color: #e0dfdf !important;
          color: #aaa;
        }

        &.time-dialog-cell-content--read-only,
        &.time-dialog-cell-content--disabled {
          cursor: not-allowed;

          & label.treehouse-checkbox,
          & label.treehouse-checkbox div {
            cursor: not-allowed;
          }
        }
      }
    }

    .time-dialog-totals {
      .time-dialog-cell-title {
        padding: 0;
      }

      .time-dialog-cell-content {
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .time-dialog .time-dialog-cells {
    flex-direction: column;

    .time-dialog-cell {
      flex-direction: row;

      .time-dialog-cell-title {
        width: 100px;
      }

      .time-dialog-cell-content {
        flex-grow: 1;
      }

      &.time-dialog-totals {
        align-items: center;

        .time-dialog-cell-content {
          align-items: center;
          display: flex;
          justify-content: center;
          min-height: 62px;
        }
      }
    }
  }
}
