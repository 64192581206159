.announcement {
  background-color: white;
  border-radius: 5px;
  padding: 15px 10px;
  min-height: 100px;
  margin: 10px 10px;
  box-shadow: 2px 1px 3px #b5b5b5;

  p {
    font-size: 14px;
  }

  .btn {
    background-color: #3264af;
    color: white;
    border-radius: 4px;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #f7941e;
    }
  }

  a {
    &.attachment {
      color: #333;
      border: 1px solid transparent;

      &:focus {
        text-decoration: none;
      }

      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        text-decoration: none;
        border: 1px solid #dadada;
        border-radius: 4px;
      }
    }
  }

  .content {
    min-height: 100%;
  }

  hr {
    margin-top: 10px;
    margin-bottom: 10px;
    border: 0;
    border-top: 1px solid #eeeeee;
  }

  h2 {
    font-size: 16px;
    color: #333;
    font-weight: 900;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .initiative-logo {
    img {
      height: 25px;
    }
  }

  .date {
    margin-top: -20px;
    float: right;
  }

  .attachment {
    cursor: pointer;
    padding: 5px;

    &:before {
      content: '\f0c6'; // paper clip and a regular space
      font-family: FontAwesome;
      margin-right: 5px;

      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        text-decoration: none !important;
      }
    }
  }
}
