.fadein-enter-active,
.fadein-exit-active {
  transition: opacity 0.3s;
}
.fadein-slow-enter-active,
.fadein-slow-exit-active {
  transition: opacity 3s;
}

.fadein-enter,
.fadein-slow-enter {
  opacity: 0;
  &.fadein-enter-active,
  &.fadein-slow-enter-active {
    opacity: 1;
  }
}

.fadein-exit,
.fadein-slow-exit {
  opacity: 1;
  &.fadein-exit-active,
  &.fadein-slow-exit-active {
    opacity: 0;
  }
}
