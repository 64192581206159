// styles pertaining to the logo in the top left corner
nav.left {
  .logo-container {
    max-width: 100%;
    height: 50px;
    overflow: hidden;
    // those variables are defined in variables.scss, having them like this lets us reuse them
    // in the transition definitions and other rules
    width: $pg-leftmenu-width;
    background-color: $color-glue;
    transition: width $def-transition-length;
    a {
      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        // prevent the hover effect on the logo itself
        background-color: inherit !important;
      }
    }
  }

  svg {
    height: 30px;
    margin: 10px 0px 0px 15px;
  }

  .nav-logo {
    height: 40px;
    width: 40px;
    margin: 5px;
  }

  .logo-hide {
    overflow: hidden;
    width: 50px;
    transition: width $def-transition-length;

    a {
      padding-left: 0px;
    }
  }

  &.expanded {
    .logo-hide {
      overflow: hidden;
      width: $pg-leftmenu-width-expanded;
      transition: width $def-transition-length;
    }
    .logo-container {
      width: $pg-leftmenu-width-expanded;
    }
  }
}
