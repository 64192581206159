@mixin timesheet-cell-colors() {
  &.timesheet-cell--submitted {
    .Select-control,
    input {
      background-color: #d7edf5 !important;
    }
  }

  &.timesheet-cell--approved {
    .Select-control,
    input {
      background-color: #daf0e5 !important;
    }
  }

  &.timesheet-cell--posted {
    .Select-control,
    input {
      background-color: #e6e6e6 !important;
    }
  }
}
