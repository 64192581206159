$background-color: #f7f7f7;
$font-color: #333;
$form-background: #fff;
$border-color: #ccc;
$button-bg-color: #007bff;
$button-bg-hover-color: #0056b3;

.ncr-dialog-content {
  background-color: $background-color;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;

  .ncr-dialog-heading {
    color: $font-color;
    margin-bottom: 20px;
    font-size: 24px;
  }

  .ncr-form {
    background-color: $form-background;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);

    form {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
    }

    .Select-control {
      border-color: #767676;

      .Select-placeholder {
        color: #333;
      }
    }

    label {
      display: flex;
      flex-direction: column;
      font-size: 16px;
      color: darken($font-color, 20%); // Making font color a bit darker
      margin-bottom: 10px;

      .ncr-input {
        padding: 8px 12px;
        border: 1px solid $border-color;
        border-radius: 4px;
        margin-top: 5px;

        &[type="checkbox"] {
          margin-top: 0; // Align checkboxes with their label text
        }
      }
    }
    select {
      width: 100%;
    }
    .ncr-required {
      display: flex;
      align-items: center;
    }
  }

  button[type="submit"] {
    padding: 10px 15px;
    background-color: $button-bg-color;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 18px;

    &:hover {
      background-color: $button-bg-hover-color;
    }
  }
}

.modal-button {
  font-weight: 900;
  border: 1px solid black;
  float: right;
  background-color: #f5f5f5;
  width: auto;

  &:hover {
    background-color: red;
    color: white;
    border: 1px solid black;
  }
}

.panel.ncr-dialog {
    max-height: 80vh;
}

.ncr-dialog {
  .panel-title {
    display: block;
    font-weight: 900;
  }

  .panel-footer {
    .btn.save-submit {
      @include save-button();
    }
  }

  .ncr-dialog-content {
    display: block;

    // .day-selections {
    //   display: flex;
    //   padding: 0 15px;
    // }
  }

  .ncr-form {
    form {
      display: grid;
    }
  }

  .ncr-input {
    margin-left: 1em;
  }

  .ncr-dialog-heading {
    text-align: center;
    font-size: 15px;
  }

  .ncr-dialog-cells {
    display: flex;
    padding: 15px;

    .ncr-dialog-cell {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      text-align: center;

      .ncr-dialog-date {
        font-family: AvantGardeGothicITCW02Md;
      }

      &:not(.ncr-dialog-totals) {
        .ncr-dialog-cell-content,
        .ncr-dialog-cell-checkbox:not(:disabled) {
          cursor: pointer;
        }
      }

      // &:last-child .ncr-dialog-cell-content {
      //   border-right: 1px solid #8e8e8e;
      // }

      .ncr-dialog-cell-checkbox {
        left: 3px;
        margin: 0;
        position: absolute;
      }

      .ncr-dialog-cell-title {
        font-weight: bold;
        margin: 0;
        padding: 10px 0;
      }

      .ncr-dialog-cell-content {
        padding: 5px 0;
        position: relative;
        border-radius: 3px;
        margin: 5px;
        border: 1px solid #cdcdcd;

        &.ncr-dialog-cell-content--approved {
          background-color: #daf0e5 !important;
        }

        &.ncr-dialog-cell-content--submitted {
          background-color: #d7edf5 !important;
        }

        &.ncr-dialog-cell-content--posted {
          background-color: #e6e6e6 !important;
        }

        &.ncr-dialog-cell-content--read-only {
          background-color: #e0dfdf !important;
          color: #aaa;
        }

        &.ncr-dialog-cell-content--read-only,
        &.ncr-dialog-cell-content--disabled {
          cursor: not-allowed;

          & label.treehouse-checkbox,
          & label.treehouse-checkbox div {
            cursor: not-allowed;
          }
        }
      }
    }

    .ncr-dialog-totals {
      .ncr-dialog-cell-title {
        padding: 0;
      }

      .ncr-dialog-cell-content {
        flex-grow: 1;
      }
    }
  }
}

@media (max-width: 991px) {
  .ncr-dialog .ncr-dialog-cells {
    flex-direction: column;

    .ncr-dialog-cell {
      flex-direction: row;

      .ncr-dialog-cell-title {
        width: 100px;
      }

      .ncr-dialog-cell-content {
        flex-grow: 1;
      }

      &.ncr-dialog-totals {
        align-items: center;

        .ncr-dialog-cell-content {
          align-items: center;
          display: flex;
          justify-content: center;
          min-height: 62px;
        }
      }
    }
  }
}