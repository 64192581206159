@media (max-width: 991px) {
  .desktop-control,
  .timesheet-project-header,
  .timesheet-desktop-controls,
  .timesheet-totals--grand-total {
    display: none;
  }

  .timesheet-container .alert {
    button.close {
      width: 50px;
    }
  }

  .col-day {
    align-items: center;
    display: flex;
    height: 36px;
    margin-bottom: 10px;

    .fa {
      line-height: 20px;
    }

    .day {
      padding-left: 0;
    }

    .day-num {
      padding-left: 30px;
    }
  }

  .grand-total {
    border: none;
    height: auto;
    display: inline;
    position: absolute;
    color: white;
    right: 0;
    padding-top: 10px;

    span {
      display: inline-block !important;
    }

    .timesheet-totals--grand-total {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .timesheet-project {
    margin-bottom: 19px;
  }

  .day-titles > * {
    .fa {
      position: absolute;
      right: 10px;
    }
  }

  .day-titles,
  .day-inputs {
    padding-left: 0;
    padding-right: 0;
  }

  // fix scrolling on iphone
  // when using relative position, it scrolls the whole page instead of just the component that has overflow
  .day-inputs,
  .timesheet-detail-inner,
  .timesheet-days,
  .timesheet-cell {
    position: static;
  }

  .timesheet-detail-inner {
    .timesheet-totals {
      .timesheet-days {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .timesheet--percentcompleteselection {
    padding: initial;   
    display: initial;
  }

  .percent-complete-title {
    align-items: center;
    display: flex;
    height: 36px;
    .percent-complete-title--disabled {
      display: none;
    }    
  }

  .timesheet--opoutlookselection {
    padding: initial;
    display: initial;    
  }

  .opoutlook-title {
    align-items: center;
    display: flex;
    height: 36px;
    .opoutlook-title--disabled {
      display: none;
    }    
  }

  .percent-complete-row {
    margin-bottom: 10px;
  }

  .op-outlook-row {
    margin-bottom: 10px;
  }

  .project-title {
    display: none;
  }

  .title {
    height: 40px;
    line-height: 40px;
    margin-bottom: 9px;
  }

  .project-selection {
    position: relative;
  }

  .timesheet-line {
    height: auto;
    padding-bottom: 20px;
    padding-top: 20px;
    position: relative;
    overflow: visible;

    .timesheet-cell {
      padding-right: 0;
      padding-left: 0px;
      margin-bottom: 10px;
      padding-top: 2px;

      input {
        height: 34px;
      }

      .timesheet-cell--overnight {
        // don't need negative offset, thanks to the smaller font
        top: 5px;
      }
    }
    .day-inputs {
      position: static;
    }
    .timesheet-days {
      position: static;
    }
  }

  .timesheet-line--daytype {
    .total {
      position: absolute;
      margin-top: 9px;
      text-align: right;
    }

    .timesheet-cell--daytype {
      margin-bottom: 7px !important;
    }
  }

  .timesheet-line--oncall {
    .total {
      position: absolute;
      margin-top: 9px;
      text-align: right;
    }

    .timesheet-cell--oncall {
      margin-top: 17px;
      margin-bottom: 15px !important;
    }
  }

  .line-controls {
    text-align: right;
    display: inline-block;
    width: 100%;

    span:first-child {
      text-transform: uppercase;
      font-family: AvantGardeGothicITCW02Dm;
      padding-right: 10px;
      text-align: right;
    }

    .timesheet-line--note-indicator {
      display: inline-block;
      margin-left: 15px;
    }
  }

  .timesheet-totals {
    background-color: white;

    .timesheet--submit {
      display: none;
    }

    .timesheet-totals {
      border-top: none;
    }

    .weekly-totals {
      padding: 0;
      background-color: #1f398d;
    }

    .project-selection {
      padding-top: 8px;
      padding-bottom: 5px;
      color: white;
      width: 100%;
      display: none;
    }

    .total-days {
      border-top: none;
      border-bottom: 5px solid #d3d3d3;
      padding-top: 0px;
      height: 70px;
      margin-top: 0px;
      padding-top: 10px;
    }

    .col-md-2 {
      margin-top: 0px;
      width: 20%;
      margin-top: 5px;
    }

    .col-xs-1 {
      width: 20%;
    }

    .equal {
      border-bottom: 10px solid #3cb878;
      z-index: 1000;
    }

    .over {
      border-bottom: 10px solid #fb5d5d;
      z-index: 1000;
    }

    .under {
      border-bottom: 10px solid #d3d3d3;
      z-index: 1000;
    }

    .background-bar {
      background-color: #27aae1;
      height: 10px;
      position: fixed;
      width: 100%;
      bottom: 0;
    }

    .mobile-menu-toggle {
      display: block;
      color: white;
      cursor: pointer;
      background-color: #27aae1;
      // position: absolute;
      // top: 0px;
      // left: 0px;
      padding: 6px 10px 1px 10px;
    }

    .timesheet-cell {
      float: left;
      width: 20%;
    }
  }

  .weekends {
    .timesheet-totals .timesheet-cell {
      width: 14.28%;
    }
  }

  // .timesheet-cell--total {
  //   padding-top: 11px;
  //   padding-bottom: 14px;
  // }

  .timesheet-cell--total--mobile {
    width: 14.28%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0;
    text-align: center;
  }

  .subtotals {
    display: flex;
    padding-left: 0;
    padding-right: 0;
  }
}
