@media (max-width: 800px) {
  // main {
  //   left: $pg-leftmenu-width !important;
  // }
}

@media (max-width: 500px) {
  .dashboard {
    .calendar-widget {
      display: none;
    }
  }

  .search-bar {
    .right-stuff {
      h4 {
        display: none;
      }
    }
  }
}

@media (max-width: 800px) {
  .dashboard {
    display: none;
  }

  .dashboard-container {
    .sidebar-container {
      width: 100%;
    }
  }
}
