// expanded state for the nav bar
nav.left.expanded {
  // width: $pg-leftmenu-width-expanded;
  // position: absolute;
  // overflow: hidden;
  // white-space: nowrap;
  // // text-align: left;

  // a {

  //   &:hover {
  //     width: $pg-leftmenu-width-expanded;
  //   }

  // }

  button {
    padding-left: 18px;
  }

  i {
    padding-right: 10px;
    height: 54px;
    transition: all $long-transition-length;
  }

  .fa-chevron-circle-right {
    padding-right: 10px;
    height: 54px;
    transform: rotate(180deg);
  }

  // .toggle {
  //   width: auto;
  // }
}
