// navbar height (which determines the logo size)
// (we don't use the variable height right now)
$pg-navbar-height-lg: 50px;
$pg-navbar-height-md: 50px;
$pg-navbar-height-sm: 50px;
// left menu sizes
$pg-leftmenu-width: 50px;
$pg-leftmenu-width-expanded: 180px;
$pg-navbar-sm-breakpoint: $screen-sm; // 768px
$pg-navbar-md-breakpoint: $screen-md; // 992px

$def-transition-length: 0.3s;
$long-transition-length: 0.5s;
$short-transition-length: 0.2s;

$color-glue: rgb(23, 95, 157);
$color-background: #f7f7f7;

@import url('https://fonts.googleapis.com/css?family=Oswald');
