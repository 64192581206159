// Approval specific styles

// horizontal container for employee list + approval detail / summary
.approval-view-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;

  .fill-space {
    background: #f7f7f7;
    flex-grow: 1;
    border-right: 1px solid lightgrey;
  }

  .approval-controls {
    border-right: 1px solid lightgrey;

    div.form-group {
      margin-bottom: 8px;
    }
  }

  #recall {
    .caret {
      margin-left: 7px;
    }
  }

  .placeholder-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  .placeholder-text {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }

  .placeholder-heading {
    background: #333;
    min-height: 45px;
    width: 100%;
  }

  .timesheet-sidebar-dock {
    position: relative;
    font-size: large;
    color: white;
    line-height: 1
  }

  .timesheet-sidebar-dock--no-time {
    position: relative;
    font-size: large;
    color: white;
    line-height: 1;
    padding-left: 12px;
    top: 12px;

    a {
      color:white;
    }
  }

  .approval-employee-list {
    width: 230px;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: relative;
    height: 100%;

    .spinner-container {
      top: 50px; // space for heading
    }

    .checkbox label {
      font-size: 12px;
    }

    input[type='checkbox'] {
      margin-top: 2px;
    }

    input[type='text'] {
      width: 100%;
      border-radius: 3px;
      padding: 5px 6px;
      font-size: 14px;
      border: 1px solid #cfcfcf;
    }

    .approval-controls {
      padding: 10px;
    }

    h2 {
      margin-top: 15px;
      font-family: 'AvantGardeGothicITCW02Md';
      font-size: 14px;
    }

    .list-scroll {
      overflow: auto;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      border-right: 1px solid lightgrey;

      li {
        cursor: pointer;

        &:nth-child(odd) {
          background: #eeeeee;
        }

        &:nth-child(even) {
          background: #e0e0e0;
        }
      }
    }
  }

  .approval-employee-item {
    position: relative;

    a {
      color: #333;
      display: block;
      line-height: 20px;
      padding: 5px 10px 5px 27px;
      text-decoration: none;
    }

    i.curtain-arrows {
      font-size: 14px;
      position: absolute;
      top: 10px;
      left: 12px;
      color: #6b6b6b;
    }

    i.entered-time-warning {
      padding: 3px 5px 3px 7px;
    }

    ul.timesheets {
      li {
        &:not(.approval-employee-item--selected) a {
          html.can-touch &:active,
          html:not(.can-touch) &:hover {
            background-color: #daeaf7;
          }
        }

        &.approval-employee-item--selected a {
          background-color: #333;
          color: #fff;
        }

        &.approval-employee-item--selected i {
          background-color: #333;
          color: #fff;
        }

        div {
          a {
            i {
              position: relative;
              top: 0px;
              left: -10px;
            }
          }
        }        
      }
    }

    .timesheet-notifications {
      background: #cdcdcd;
      border-radius: 5px;
      color: #fff;
      font-size: 12px;
      padding: 2px 6px;
      margin-left: 10px;
    }
  }

  .empty-employee-item {    
    width: 100%;
    overflow: hidden;    
    background: white;

    div.employee-name {                   
      padding: 5px 10px 5px 27px;
      float: left;
      width: 80%;
    }

    a.timesheet-link {
      float: right;
      color: #6b6b6b;
      padding: 5px 10px 5px 16px;  
      width: 20%    
    }

    i.entered-time-warning {      
      padding: 3px 5px 3px 7px;
    }
  }

  .approval-timesheet-link {
      color: #333;
      display: block;
      line-height: 20px;
      padding: 5px 10px 5px 27px;
      text-decoration: none;                       
  }

  .approval-links {
    width: 100%;
    overflow: hidden;
    float: left;
    background: white;
  }

  .approval-employee-item-goto { 
    float: left;
    width: 80%;
  }

  .approval-employee-timesheet {
    overflow: hidden;
  }

  .timesheet-container {
    position: relative;
    flex: 1;
  }

  .timesheet-calendar-header {
    cursor: default;
  }

  h4 {
    padding: 8px 10px;
    margin: 0;
    margin-left: 17px;
    font-size: 14px;
  }

  .detail-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .detail-view-scroll {
    overflow-y: auto;
    height: 100%;
  }

  .detail-view {
    max-height: 100px;
    // overflow: auto;
    // height: 100%;

    & > table {
      width: 100%;
    }

    tr {
      &:nth-child(2n) {
        background-color: rgba(51, 51, 51, 0.05);
      }
    }

    tr.timesheet-project-header {
      height: 50px;
      text-transform: uppercase;
      padding-top: 8px;
      font-family: AvantGardeGothicITCW02Dm;
      -webkit-box-shadow: none;
      box-shadow: none;
      background-color: #f9f9f9;
      color: #333;
      margin-bottom: 10px;
      border-bottom: 1px solid lightgrey;
      border-top: 1px solid lightgrey;

      th {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .project-selection {
      position: relative;
      width: calc(100% - 82px);
      margin-right: 5px;
      display: inline-block;
    }

    .timesheet-delete {
      height: 35px;
    }

    .col-header span {
      position: absolute;
      padding-left: 5px;
    }

    .timesheet-cancel {
      height: 34px;
      margin-bottom: 2px;

      &:hover {
        background-color: #808080;
        color: white;
      }
    }

    th {
      position: relative;
    }

    th,
    td {
      padding-left: 15px;
    }

    td {
      padding-bottom: 8px;
      padding-top: 8px;

      .timesheet-cell {
        width: 110px;
        @include timesheet-cell-colors;
      }

      .timesheet-line--note-indicator {
        position: inherit;
      }

      .timesheet-line--note-indicator-detail {
        padding-right: 0;
        align-items: center;
        text-align: center;
      }
    }

    td.projectName {
      width: calc(100% - 600px);
      min-width: 400px;
      display: flex;

      div {
        max-width: 500px;
        vertical-align: top;
      }
    }

    td.no-entries {
      position: relative;
      height: 100px;
    }

    .projectName > div {
      display: inline-block;
    }

    button.timesheet-edit {
      background-color: white;
      color: black;
      height: 35px;
      margin-right: 5px;
      width: 39px;
      border-radius: 4px;
      border: 1px solid lightgrey;

      &:hover {
        background-color: #808080;
        color: white;
      }

      &.disabled {
        pointer-events: none
      }
    }

    .MuiTableCell-body {
      font-size: small
    }

    .MuiTableCell-head {
      font-size: 14px;
      color: black;
      font-weight: bold;
    }
    
    .MuiToolbar-regular {
      font-size: small
    }

    .MuiTypography-caption {
      font-size: inherit
    }

    .MuiIcon-root {
      font-size: x-large
    }

    .MuiIcon-fontSizeSmall {
      font-size: large
    }

    .MuiInputBase-root {
      font-size: small
    }

    .MuiTableCell-root {
      font: revert;
    }

    .MuiTableHead-root {
      color: black;
    }

    .MTableHeader-header-202 {
      color: black
    }

    .material-table-job {
      display: flex;
      padding-inline-start: initial;
      padding-top: 4px;
    }

    .material-table-hours {
      padding-right: 0px;
      padding-left: 0px;
    }

    .approval-detail-cell--overnight {
      top: 5px;
    }
  }

  .timesheet-footer {
    position: relative;
  }

  .timesheet-navigation {

    .timesheet-sidebar-dock {
      position: absolute;
      margin: auto;
    }

    .timesheet-controls {
      align-items: center;
      margin: 0 auto;
      justify-content: space-between;
    }

    .timesheet-header {
      margin: auto;
    }
  }
}