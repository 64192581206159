// label-flip mixin
// use as:
// <div class="label-flip">
//   <label>Front Label</label>
//   <label class="back">Back Label</label>
// </div>
//
// Add a "flipped" class to flip it
.label-flip {
  position: relative;

  label {
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden !important;
    transform: rotateY(0);
    transition: transform 0.3s;

    // the back label is absolutely positionned so that it overlaps the
    // front one
    // the translate transforms are used to center it
    &.back {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%) rotateY(180deg);
    }
  }

  &.flipped {
    label {
      transform: rotateY(180deg);
    }
    .back {
      transform: translateX(-50%) translateY(-50%) rotateY(0);
    }
  }
}
