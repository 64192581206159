// Transition definition for page
.slide-page {
  transition: transform 0.3s;
  transform: translate(0, 0);
}
.slide-page.exit {
  &.left.active {
    transform: translate(100%, 0);
  }
  &.right.active {
    transform: translate(-100%, 0);
  }
  &.up.active {
    transform: translate(0, 100%);
  }
}
.slide-page.enter {
  &.left {
    transform: translate(-100%, 0);
  }
  &.right {
    transform: translate(100%, 0);
  }
  &.up {
    transform: translate(0, -100%);
  }
  &.active {
    transform: translate(0, 0);
  }
}
