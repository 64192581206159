@import '_logout';

.form-signin {
  margin: auto;
  width: 400px;
  max-width: 90%;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img.tait-logo {
    width: 100% !important;
  }

  .login-wrap {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
  }

  .logout-wrap {
    h4 {
      font-size: 16px;
      text-align: center;
    }
  }

  .btn-login {
    display: block;
    margin-top: 20px;
    color: white;
    background-color: #1e5d9e !important;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #f7941e !important;
    }
  }

  // "need assistance" blurb
  .login-help {
    margin-top: 1.5rem;
    text-align: center;
  }

  .form-control {
    display: block;
    width: 100%;
    // do the height, rather than adding to padding, bc of IE
    height: 42px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }

  // avoid grayish background when logging in - https://trello.com/c/BCUwZtZn
  .spinner-container {
    background: transparent;
  }

  .error {
    text-align: center;
    border-top: black;
    border-top-width: thin;
    border-top-style: solid;
    padding-top: 10px;
    font-size: smaller;
    font-style: italic;
  }
}
