.calendar-widget {
  background-color: white;
  width: calc(100% - 20px);
  margin: 10px;
  margin-bottom: 25px;
  border-radius: 10px;
  box-shadow: 2px 1px 3px #b5b5b5;

  h2 {
    display: inline-block;
    font-size: 18px;
  }

  button {
    display: inline-block;
    margin-left: 15px;
    position: absolute;
    top: 17px;
  }

  // see also calendar.scss under the projects folder!
}
