/*
 * Timesheet module styles
 */

// PUT LAYOUT STUFF IN HERE
@import '_layout';

// MIXINS
@import './_timesheetCellColors';

body {
  margin-bottom: 125px;
  font-family: AvantGardeGothicITCW01Bk;
  user-select: none;
}

.panel {
  border: none;
  .panel-heading {
    border-bottom: 1px solid #ddd;
  }
  .bg-primary {
    color: #333;
    background: #f5f5f5;
  }
}

// the top level container for the timesheet
// (this can have more than 1 timesheets in it while there is a transition!)
.timesheet-container {
  // hide overflow otherwise the transition will have a scrollbar
  overflow: hidden;
  // margin-bottom: 130px;

  .alert {
    margin-bottom: 0;
    position: absolute;
    z-index: 9999;
    left: 0;
    right: 0;
  }

    // fade-in alert styles for Time Approval Banner Success Alert
    .alert-success {
      padding: 8px;
      color: black;
      top: 85.4%;
      display: inline-table;
      width: 95% !important;
      text-align: center;
      justify-content: center;
      align-items: center;
      opacity: 0;
      animation: fadeIn 6s ease-in-out;
    }
  
    @keyframes fadeIn {
      0%, 100% {
          opacity: 0;
      }
      20%, 80% {
          opacity: 1;
      }
  }

  .timesheet-project {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding-left: 15px;
    padding-right: 15px;

    .project-selection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
  }

  // .timesheet-detail-unsubmitted {
  //   display: flex;
  //   justify-content: center;
  // }

  .timesheet-detail-unsubmitted--link {
    color: #3264af;
    &:hover {
      filter: brightness(85%)
    }
  }

  // the scrolling container - with ONLY the rows
  .timesheet-detail-scroll {
    // position: absolute;
    overflow-y: scroll;
  }

  .timesheet-desktop-controls {
    padding: 5px 15px;

    a {
      color: #333;
    }

    div {
      vertical-align: middle;
    }

    .timesheet-show-weekends {
      align-items: stretch;
      display: flex;
      vertical-align: text-top;
    }

    .timesheet--userselection {
      width: 30%;
      display: inline-block;

      label {
        margin-right: 10px;
        vertical-align: text-top;
        white-space: nowrap;
        width: 94px;
      }

      .Select {
        width: calc(100% - 105px);
        display: inline-block;
      }
    }
  }

  // the content of the timesheet (i.e. the rows)
  .timesheet-detail-inner {
    margin-bottom: 60px;
    padding-left: 0px;
    padding-right: 0px;
  }

  .timesheet-project-header {
    height: 50px;
    text-transform: uppercase;
    padding-top: 8px;
    font-family: AvantGardeGothicITCW02Dm;
    box-shadow: none;
    background-color: #f9f9f9;
    color: #333;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;

    .day-titles {
      text-align: center;
      padding-left: 0px;
      padding-right: 0px;
      margin-top: 7px;
    }

    .project-heading {
      padding-left: 8px;
      text-align: center;
    }
  }
}

.timesheet-line--note-indicator {
  .btn {
    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background: #f7941e;
      color: white;
    }
  }
}

.timesheet-delete {
  margin-right: 0px;
  background-color: white;
  color: black;
  border-radius: 4px;
  border: 1px solid lightgrey;
  float: right;

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: #808080;
    color: white;
  }
}

.timesheet-project,
.project-title {
  padding-top: 0px;
}

.timesheet-days {
  button {
    margin-left: 15px;
    border: 1px solid lightgrey;
    background-color: transparent;
  }
}

.timesheet-navigation {
  // height: 50px;
  color: #fff;
  background-color: #333;
  border-bottom: 1px solid #333;

  .timesheet-controls {
    padding-top: 15px;
    padding-bottom: 9px;
    display: flex;
    justify-content: center;

    // & > div {
    //   flex: 1;
    // }

    nav {
      margin-left: 10px;
      margin-right: 10px;
    }

    .timesheet-header {
      display: flex;      
      flex-direction: row;
      flex-wrap: wrap;
      font-family: AvantGardeGothicITCW02Dm;
      font-size: 16px;
      justify-content: center;
      line-height: 20px;
  
      i {
        padding-right: 10px;
      }
    }
  }
  

  .timesheet-calendar-header {
    cursor: pointer;
  }

  .timesheet-employee-header {
    padding-left: 5px;
  }

  a {
    color: #fff;
    display: block;
    cursor: pointer;
    padding: 0px 10px 5px 10px;
  }
}

// lineheader row group

.timesheet-line {
  border-radius: 0;
  padding-top: 12px;
  box-shadow: none;
  margin: 0px;

  &.timesheet-line--closed {
    background-color: #e6e6e6;
  }

  // non chargeable lines
  // &.timesheet-line--overhead {
    // background-color: orange;
  // }

  // TimesheetSubTotals
  // &.timesheet-line--subtotals {
    // background-color: cyan;
  // }

  // for overnight comp / in lieu (DayTypeSelectionRow)
  &.timesheet-line--daytype {
    // background-color: pink;
    .Select-menu {
      max-height: 8.5em;
    }

    .Select-value {
      font-size: 12px;
    }
  }

  &.timesheet-line--new {
    // background-color: white;
    .timesheet-days {
      .form-control {
        background-color: #e6e6e6 !important;
        border: 1px solid #eaeaea;
      }
    }

    .timesheet-line--note-indicator {
      display: none;
    }
  }

  &:not(.timesheet-line--new) .timesheet-cell {
    @include timesheet-cell-colors;
  }

  .percentcompleteselection--completed {  
    .Select-control {
      background-color: #F9FF33 !important;
    }
  }

  .timesheet-project {
    display: flex;

    .btn {
      margin-right: 0px;
      background-color: white;
      color: black;
      height: 35px;
      border-radius: 4px;
      border: 1px solid lightgrey;

      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        background-color: #808080;
        color: white;
      }

      &.timesheet-edit {
        margin-right: 5px;
        
        &.disabled {
          pointer-events: none
        }
      }
    }

    .project-update .btn {
      margin-left: 9px;
      margin-right: 0;
    }
  }
}

// a day cell (they are used for the totals, as well as for the entry cells)
.timesheet-cell {
  padding-left: 2px;
  padding-right: 2px;

  &.has-icon {
    position: relative !important;
  }

  .form-control {
    background-color: white !important;
    padding: 6px 7px;
    transition: opacity 0.2s;
    appearance: textfield;
  }

  .timesheet-cell--overnight {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -5px;
    right: 15px;
    color: #478eff;

    // "bed" icon
    &::before {
      content: '\f236';
      font-family: 'FontAwesome';
    }
  }

  .timesheet-cell--overnightoff {
    position: absolute;
    width: 10px;
    height: 10px;
    top: -5px;
    right: 15px;
    color: #585451;

    // "bed" icon
    &::before {
      content: '\f236';
      font-family: 'FontAwesome';
    }
  }

  &.timesheet-cell--disabled {
    .form-control {
      background-color: #e6e6e6 !important;
      border: 1px solid #eaeaea;
    }
  }

  &.timesheet-cell--save-pending .form-control {
    opacity: 0.5;
  }

  &.timesheet-cell--invalid,
  &.timesheet-cell--invalid .Select-value-label {
    color: red !important;
  }

  .form-control-feedback {
    width: 29px;
    color: #d2d2d2;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.totals-data {
  .col-xs-1 {
    padding-right: 0px;
  }

  span {
    display: block;
    text-align: center;

    .totals-time {
      padding-bottom: 5px;
      font-weight: bold;
    }
  }

  @media (max-width: 991px) {
    .col-xs-1 {
      width: 10.33%;
    }

    .col-xs-1,
    .col-md-1 {
      padding-left: 0;
    }

    .total-caption {
      display: none;
    }
  }
}

.timesheet-total-details {
  margin-top: 20px;

  @media (min-width: 991px) {
    margin-top: 28px;
  }
}

.timesheet-other-line {
  text-transform: uppercase;

  .timesheet-project-selection,
  .project-selection {
    padding-top: 8px;
    font-family: AvantGardeGothicITCW02Dm;
  }
}

.timesheet-totals {
  width: 100%;
  margin-right: 0 !important;
  margin-left: 0 !important;
  background-color: white;
  z-index: 10;
  font-family: AvantGardeGothicITCW02Dm;
  text-transform: uppercase;

  .total-caption {
    padding-top: 28px;
  }

  .timesheet-project {
    text-align: center;
  }

  .col-xs-1 {
    padding-left: 0px;
    padding-right: 0px;
    width: 12.3333%;
    text-align: center;
  }

  .col-xs-5 {
    padding-left: 0;
    padding-right: 0;
  }

  .project-selection {
    width: 100%;
    margin-left: -15px;
    margin-right: -15px;
    padding-top: 35px;
    position: relative;

    @media (max-width: 991px) {
      width: 110%;
      padding-top: 8px;
      background-color: #1e398d;
      color: white;
      height: 35px;
    }
  }

  .submit-check {
    display: block;
    margin-bottom: 5px;
  }

  .mobile-menu-toggle {
    display: none;
  }

  .timesheet-days {
    background-color: transparent;
    height: 100%;
    z-index: 11;

    @media (max-width: 991px) {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .total-days {
    align-items: center;
    border-top: 10px solid transparent;
    color: #333;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 17px;
    padding-top: 25px;
    text-align: center;
    padding-right: 19px;

    span {
      &.timesheet-totals--grand-total {
        margin-top: 7px;
      }
    }

    .total-time {
      margin-top: 7px;
    }
  }

  .project-selection {
    text-align: center;
    padding-top: 40px;
    color: #333;
    position: relative;
  }

  // .equal {
  //   border-top: 10px solid #3cb878;
  // }
  //
  // .over {
  //   border-top: 10px solid #fb5d5d;
  // }
  //
  // .under {
  //   border-top: 10px solid #d3d3d3;
  // }

  .col-md-2 {
    margin-top: -17px;
  }

  .total-time {
    display: block;
  }

  .over {
    color: red
  }
}

.label {
  margin-bottom: 0 !important;
}

.form-control[readonly] {
  background-color: white;
}

.timesheet-other-line {
  border-bottom: none;
  box-shadow: none !important;
}

.widget {
  display: inherit;
}

.week-close {
  border: 1px solid #fb5d5d;
  color: #fb5d5d;
  transition: all 0.4s;
  border-radius: 4px;
  background-color: transparent;
  float: left;
  margin-left: 0 !important;

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: #fb5d5d;
    color: white;
  }
}

.go-to-today {
  border: 1px solid orange;
  color: orange;
  transition: all 0.4s;
  border-radius: 4px;
  background-color: transparent;
  float: right;

  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: orange;
    color: white;
    border: 1px solid orange;
  }
}

// > 991
@import '_desktop-styles';
// > 991, < 1300
@import '_small-laptop-styles';
// < 991
@import '_mobile-styles';

///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////

@import './_buttons.scss';
@import './_mobileDashboard.scss';
@import './_calendarDialog.scss';
@import './_noteDialog.scss';
@import './_weekEndToggle.scss';
@import './_switchingTimesheet.scss';
@import './_timesheetWidget.scss';
@import './_timeDialog';
