// State for the unexpanded nav bar, and default nav bar styles
nav.left {
  background-color: #2a2d33;
  height: 100%;
  flex-basis: $pg-leftmenu-width;
  z-index: 1001; // search bar is 1000, we want to be above that, but still below the dialog overlays
  transition: flex-basis $def-transition-length;
  overflow: hidden;
  flex-shrink: 0;

  &.expanded {
    flex-basis: $pg-leftmenu-width-expanded;
  }

  .menu-indicator {
    position: absolute;
    top: 8px;
    right: 3px;
    color: white;
  }

  .menu-control {
    position: absolute;
    width: $pg-leftmenu-width;
    bottom: 10px;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      i:nth-child(2) {
        color: orange;
      }
    }
  }

  // the menu links
  a {
    // width: 50px;
    cursor: pointer;
    display: block;
    color: white;
    padding-left: 18px;
    text-decoration: none;
    position: relative;
    // prevent dotted outline on Firefox
    outline: 0;

    span {
      vertical-align: text-top;
    }

    // something special for the currently active page?
    &.active {
      &,
      i {
        color: #69d0f6;
      }

      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        background-color: #2a2d33;
      }
    }

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #5eb8da;
    }

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%); // center
      left: $pg-leftmenu-width; // make it hidden when navbar is collapsed
      white-space: nowrap;
    }
  }

  .toggle {
    width: 100%;
    height: 50px;
    background-color: #2a2d33;
    border: none;
  }

  // &.collapsed a span {
  //   display: none;
  // }

  i {
    color: white;
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .nav-titles {
    display: none;
  }

  i.fa.fa-lg.fa-chevron-circle-right {
    transition: all $long-transition-length;
  }
}
