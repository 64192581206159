// Custom attributes for spinner widget on the glue portal

// containers that are marked as "spinner ready" can be completely shadowed by it
// (this is used on the timesheet page)
.has-spinner .spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// over modal, need to use fixed pos so it stretches over the whole page and not just the modal content
.modal .spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// when included as child of the app node, this is when loading the metadata containers, we need to
// stretch over the whole page too
.app > .spinner-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  //@include left-menu-space-appnode();
}
