// Portal page (aka dashboard)
@import '_calendarWidget';
@import '_announcement';

.portal-widget {
  position: relative;
  min-height: 100px;

  .spinner-container {
    border-radius: 10px;
  }
}

.calendar-widget,
.timesheet-widget {
  display: block;
}

.calendar-widget {
  height: auto;
}

.announcement-feed {
}

// the container for the dashboard's main area
.dashboard {
  width: 75%;
  overflow: auto;
}

// the scrolling container for the sidebar
.sidebar-container {
  background-color: #ededed;
  height: 100%;
  // this z-index causes the element to be unfocusable
  // not sure why it was there in the first place
  // commenting it out for now
  // z-index: -300;
  width: 25%;
  float: right;
  overflow: auto;
}

// the top level container for the dashboard.
// we don't scroll that one because we need the left and right panes to scroll independently
.dashboard-container {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.app-container,
.app,
.dashboard {
  height: 100%;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

.app {
  display: flex;
}

#app-container {
  height: 100%;
}
