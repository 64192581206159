// additional styles for the Bootstrap DateTimePicker

.bootstrap-datetimepicker-widget.dropdown-menu {
  width: auto;
}

.input-group.date {
  .clearbutton {
    border: none;
    padding: 0 2px;
    background-color: transparent;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    bottom: -10px;
    font-size: 18px;
    right: 5px;
    width: 16px;
    cursor: pointer;
    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      color: #d0021b;
    }
  }
  html.can-touch &:active .clearbutton,
  html:not(.can-touch) &:hover .clearbutton {
    opacity: 1;
  }
  &.has-addon .clearbutton {
    right: 45px;
  }
  &.has-updown .clearbutton {
    right: 16px;
  }
  &.has-updown.has-addon .clearbutton {
    right: 61px;
  }
  &:not(.has-addon) .datepickerbutton {
    display: none;
  }
}

.daterange-filter {
  .input-group.date {
    input {
      font-size: 12px;
    }
    .clearbutton {
      bottom: -5px;
    }
  }
}
