.timesheet-widget {
  background-color: white;
  width: calc(100% - 20px);
  margin: 10px;
  padding: 0;
  border-radius: 10px;
  box-shadow: 2px 1px 3px #b5b5b5;

  .timesheet-detail-scroll {
    position: static;
    overflow: hidden;
  }

  .timesheet--submit {
    float: right;
    background-color: #3cb878;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #2ba265;
    }
  }

  .timesheet-project {
    padding-left: 25px;
  }

  .alert-warning {
    margin-bottom: 0;
    position: static !important;
    z-index: 9999;
    left: 0;
    right: 0;
  }

  .timesheet-show-weekends {
    margin-left: 15px;
    label {
      margin-bottom: 0px;
    }
  }

  .col-md-5 {
    width: 41.666667%;
    margin-left: 0px;
  }

  .equal {
    border-bottom: 5px solid #3cb878;
    height: 34px;
  }

  .over {
    border-bottom: 5px solid #fb5d5d;
    height: 34px;
  }

  .under {
    border-bottom: 5px solid lightgrey;
    height: 34px;
  }

  .timesheet-widget-title {
    button {
      border: 1.5px solid #5eb8da;
      background-color: transparent;
      border-radius: 3px;
      color: #5eb8da;

      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        background-color: #5eb8da;
        color: white;
      }
    }
  }

  .timesheet-project-header {
    box-shadow: none;
    background-color: #f9f9f9;
    color: #333;
    border-bottom: 1px solid lightgrey;
    border-top: 1px solid lightgrey;

    .project-heading {
      padding-left: 0px;
    }
  }

  .form-control[readonly] {
    background-color: white;
  }

  .timesheet-other-line {
    border-bottom: none;
    box-shadow: none !important;
  }

  .widget {
    display: inherit;
  }

  .timesheet,
  .timesheet-detail-inner {
    margin-bottom: 0;
  }

  .timesheet-detail-innner,
  .timesheet-widget {
    overflow: hidden;
  }

  .timesheet {
    border-radius: 10px;
    overflow: auto;
  }

  .timesheet-widget-total {
    color: #333333;
    background-color: transparent;
    box-shadow: none;
    border-bottom: 5px solid lightgrey;
  }

  .timesheet-widget-total .day-of-week {
    display: none;
  }

  .timesheet-cell {
    padding-left: 2px;
    padding-right: 2px;
  }

  .timesheet-line {
    border-radius: 0;
    padding-top: 8px;
    box-shadow: none;
    background-color: white;
    margin: -5px 0px 5px 0px;

    .timesheet-project {
      padding-left: 12px;
    }
  }

  .timesheet-navigation {
    display: none;
  }

  .timesheet-days {
    .day-inputs,
    .day-titles {
      padding-left: 0;
      padding-right: 0;
    }

    button {
      margin-left: 5px;
      border: 1px solid lightgrey;
      background-color: transparent;
    }
  }

  .timesheet--clone:first-child {
    display: none;
  }

  .timesheet--clone:last-child {
    display: inline-block;
    margin-left: 15px;
  }

  .app {
    display: none;
  }
}

.desktop-control {
  margin: 10px;

  i {
    padding-right: 8px;
  }
}

.Select-menu {
  font-size: 12px;
}

.timesheet-widget-title {
  padding: 10px 15px;

  span {
    margin-right: 15px;
  }
}

@media (max-width: 991px) {
  .timesheet-widget {
    .timesheet-project {
      width: 100%;
    }

    .desktop-control {
      display: inline-block;
      background-color: #3264af;
      color: white;
      padding: 6px 12px;
      border-radius: 4px;
    }
  }
}
