.password-reset {
  float: left;
  #message {
    display: none;
    position: fixed;
    width: 400px;
    max-width: 90%;
    top: 50%;
    left: 50%;
    transform: translate((-50%, 50%));
    padding-left: 25px;
    padding-top: 15px;
    .invalid {
      color: red;
    }
    .valid {
      color: green;
    }
    .valid:before {
      position: relative;
      left: -35px;
      content: '✔';
    }
    .invalid:before {
      position: relative;
      left: -35px;
      content: '✖';
    }
  }
  #message p {
    padding-left: 35px;
  }

  .password-reset-heading {
    text-align: center;
  }
}

.form-reset {
  margin:auto;
  width: 400px;
  max-width: 90%;
  padding: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &.success {
    text-align: center;
  }

  &.error {
    text-align: center;
  }

  .reset-wrap {
    border: 1px solid #dddddd;
    border-radius: 5px;
    padding: 10px;
  }

  .invalid-token {
    text-align: center;
    background-color: #ffbcbc;
  }

  .token-expired {
    text-align: center;
    background-color: #ffbcbc;
  }

  .link {
    text-align: center;
    :link {
      color: black;
    }
  }

  .btn-submit {
    display: block;
    margin-top: 20px;
    color: white;
    background-color: #1e5d9e !important;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #f7941e !important;
    }
  }

  .form-control {
    display: block;
    width: 100%;
    // do the height, rather than adding to padding, bc of IE
    height: 42px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #ffffff;
    background-image: none;
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
  }  
}