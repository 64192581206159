// animation for a vertical accordion, see DetailViewSection for an example
.accordion-enter {
  transition: max-height 0.5s linear;
  max-height: 0;
  overflow: hidden;

  &.accordion-enter-active {
    max-height: 500px;
  }
}

.accordion-leave {
  transition: max-height 0.3s linear;
  max-height: 500px;
  overflow: hidden;

  &.accordion-leave-active {
    transition: max-height 0.3s linear;
    max-height: 0;
  }
}
