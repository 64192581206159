@media (min-width: 1100px) {
  .timesheet-line--note-indicator {
    position: absolute;
    right: 15px;
  }
}

@media (min-width: 1400px) {
  .timesheet-line--note-indicator {
    position: absolute;
    right: 25px;
  }
}

@media (min-width: 991px) {
  body {
    margin-bottom: 0px;
  }
  .ReactModal__Content {
    margin-top: 150px !important;
  }
  .timesheet-detail-inner {
    margin-bottom: 90px !important;
  }
  .timesheet-desktop-controls {
    align-items: center;
    display: flex;
  }
  .timesheet-container .alert {
    margin-bottom: 0;
    position: absolute;
    z-index: 9999;
    left: 10px;
    width: 40%;
    right: 0;
    bottom: 10px;
  }
  .timesheet-line--active {
    background: #e2f2ff;
  }
  .timesheet-line--subtotals {
    .title {
      text-align: right;
      line-height: 34px;
      text-transform: uppercase;
      font-size: 12px;
    }
    .timesheet-cell {
      text-align: center;
    }
  }
  .day-titles > * {
    .fa {
      margin-right: -9px;
      position: relative;
      right: -10px;
    }
  }
  .grand-total {
    height: 100%;
    text-align: center;
    padding-top: 35px;
    padding-bottom: 17px;
    color: #333;
    float: right;
    span {
      display: block;
    }
    .timesheet-totals--grand-total {
      margin-top: 7px;
    }
  }
  .timesheet-line--note-indicator {
    display: inline-block;
    .btn {
      padding: 0;
      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        background: none;
        color: #f7941e;
      }
    }
  }
  .line-controls {
    line-height: 34px;
    text-align: center;

    span:first-child {
      display: none;
    }
  }
  
  .timesheet--percentcompleteselection {
    padding-left: 0;
    padding-right: 0;
    .Select {
      text-align: center;
      .Select-menu-outer {
        min-width: 100px;
      }
    }
  }

  .timesheet--opoutlookselection {
    padding-left: 0;
    padding-right: 0;
    .Select {
      text-align: center;
      .Select-menu {
        font-size: medium;
      }
    }
  }

  .timesheet-cell--daytype {
    .Select {
      .Select-menu-outer {
        min-width: 250px;
      }
    }
  }
  
  // .timesheet-project, .project-title
  // {
  //   padding-top: 7px;
  // }

  .header-totals {
    max-width: 120px;
  }

  .line-controls,
  .grand-total {
    width: 120px;
  }

  .timesheet-project-header {
    margin-right: 15px;

    .row {
      margin-left: 0;
      margin-right: -15px;
      padding-right: 15px;
    }

    .header-totals {
      text-align: center;
    }

    .header-percent-complete {
      text-align: center;
    }

    .header-op-outlook {
      text-align: center;
      padding-top: 7px;
    }

    .project-heading,
    .header-totals {
      padding-top: 7px;
    }
  }
  .Select .Select-menu-outer {
    .Select-option {
      padding: 0 8px;
    }
  }
  .timesheet-line--daytype {
    padding-left: 0 !important;
    padding-right: 0 !important;
    span {
      line-height: 34px;
    }
    .title {
      padding-left: 15px;
    }
    .total {
      text-align: right;
    }
  }
  .timesheet-cell--oncall-checkbox {
    width: 100%;
    margin: auto;
    text-align: center;
  }
  .timesheet-line--oncall {
    padding-left: 0 !important;
    padding-right: 0 !important;
    span {
      line-height: 34px;
    }
    .title {
      padding-left: 15px;
    }
    .total {
      text-align: right;
    }
  }
  .col-md-5 {
    width: 41.666667%;
    margin-left: 0%;
  }
  .timesheet--clone {
    margin: 10px;
    display: inline-block;
    button {
      @include control-button;
      i {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  }
  .timesheet-totals {
    margin-left: 0px !important;
    // margin-right: -15px !important;
    width: auto;
  }
  .timesheet-footer {
    margin-right: 15px;

    .timesheet-totals {
      border-top: 2px solid #d3d3d3;

      & > * {
        margin-top: -10px;
      }
    }
  }
  .weekends {
    .col-md-2 {
      width: 12.666667%;
    }
  }
  .timesheet-totals {
    .col-xs-1 {
      padding-left: 0px;
      padding-right: 0px;
      width: 16.66667%;
    }
    .col-md-2 {
      width: 15.3%;
      padding-right: 0;
      padding-left: 0;
    }
    .col-md-6 {
      padding-right: 0;
    }
  }

  .timesheet-days.col-md-7,
  .days.col-md-7 {
    width: calc(66.66666667% - 120px);
  }

  .timesheet-days .day-titles,
  .timesheet-days .day-inputs,
  .timesheet-footer .timesheet-days,
  .timesheet-line .days {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    .timesheet-cell {
      flex: 1 1 100%;
      flex-grow: 1;      
    }

    .timesheet-cell.timesheet-cell--entry {
      display: flex;
      flex-direction: column;

      ::placeholder {
        font-style: italic;
      }
      
      .form-input {
        width: auto;
      }
    }
  }

  .project-selection {
    position: relative;
  }
  .timesheet-days {
    .day-inputs {
      // remove gutter (that is needed on the mobile version)
      padding: 0;
      margin: 0;
      justify-content: space-between;

      span {        
        flex-grow: 1;
      }
    }
    .btn {
      margin-top: 1px;
      html.can-touch &:active,
      html:not(.can-touch) &:hover {
        background-color: #808080;
        color: white;
      }
    }
  }
  .timesheet-line {
    align-items: center;
    display: flex;
    padding-top: 6px;
    padding-bottom: 6px;

    .total,
    .timesheet-cell {
      line-height: 34px;
    }
    .timesheet-delete {
      float: left;
      position: relative;
      left: 0;
      margin-right: 9px;
    }
    .day-titles {
      display: none;
    }
    .percent-complete-title {
      display: none;
    }
    .opoutlook-title {
      display: none;
    }
  }
  .project-title {
    display: none;
  }
  .timesheet-totals {
    .day-titles {
      display: inline-block;
    }
  }
}
