.req-form {
  max-height: 80vh !important;
}
.form-container {
  display: block;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  padding: 20px;
  background-color: #e8eaf6;
  border: 1px solid #ccc;
  border-radius: 8px;

  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      margin-bottom: 5px;
      font-weight: bold;
    }

    input, select, textarea {
      padding: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }
  }

  .row {
    //display: flex;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    margin-top: 15px;
  }

  .col {
    display: flex;
    flex-direction: column;
  }

  .full-width {
    grid-column: span 2;
  }

  .form-section {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 8px;
  }

  .collapse-header {
    cursor: pointer;
    font-weight: bold;
    padding: 10px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .collapse-body {
    padding: 10px;
    display: none; // Initially collapsed
  }

  .expanded {
    display: block; // Show the content when expanded
  }

  .d-flex {
    display: flex;
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .btn-success:hover {
    background-color: #218838 !important;
  }

  .btn-danger:hover {
    background-color: #c82333 !important;
  }

  .btn-primary:hover {
    background-color: #0056b3 !important;
  }
}
