// PUT LAYOUT STUFF IN HERE
// So we don't have to hunt through the whole file to figure out why shit is out of whack!
.timesheet-container {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  // a single timesheet, and the object that goes through the slide transition
  .timesheet {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .timesheet-navigation {
      flex: 0 0 auto;
    }

    // the detail, which actually has the rows + the footer
    .timesheet-detail {
      flex: 1;
      display: flex;
      flex-direction: column;
      min-height: 0px;

      .timesheet-detail-scroll {
        flex: 1;
      }
      .timesheet-footer {
        background: #fff;
        flex: 0 0 auto;
      }
    }
  }
}
