/*App Layout Styles*/

html,
body {
  // do not include the height 100% - it causes an extra scrollbar to show in the dashboard
  // we are scrolling the "content" container instead
  // height: 100%;
  padding: 0;
  margin: 0;
}

.main-page {
  background-color: $color-background;
  // Fixed position causes some issues with scrolling on iphone (freezes when scrolling lists)
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  .search-bar {
    flex: 0 0 auto;
  }

  .app-header {
    flex: 0 0 auto;
    border-bottom: 10px;
  }

  .content {
    flex: 1 1 auto;
    position: relative;
  }
}

.placeholder-heading {
  background: #333;
  min-height: 45px;
  width: 100%;
}

.modal-header .close {
  margin-top: 0px !important;
}

.timesheet-submitted {
  display: none;
}

.modal {
  display: flex;
  height: 100%;

  @media (min-width: 800px) {
    width: calc(100% - 50px);
    left: 50px;
  }
}

body.modal-open .modal .modal-dialog {
  margin: auto;
}

* {
  &:focus {
    outline: none;
  }
}
