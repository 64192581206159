.spinner-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  z-index: 5;
}
.spinner-container.kpi-loading {
  position: relative;
  height: 54px;
  width: 100%;
  background-color: transparent !important;
}
.spinner-container .spinner-inner {
  position: absolute;
  padding: 10px;
  left: 50%;
  top: 40%;
  transform: translate3d(-50%, -50%, 0);
}
@keyframes circles-loader {
  0% {
    transform: rotate(-720deg);
  }
  50% {
    transform: rotate(720deg);
  }
}
.spinner-container .circles-loader {
  position: relative;
  text-indent: -9999px;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: #03a9f4;
  border-radius: 100%;
  animation: circles-loader 3s infinite ease-in-out;
  transform-origin: 50% 82%;
}
.spinner-container .circles-loader::before {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 100%;
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: -10.82532px;
}
.spinner-container .circles-loader::after {
  background: rgba(204, 204, 204, 0.5);
  border-radius: 100%;
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  top: 18.75px;
  left: 10.82532px;
}
.spinner-container .spinner-text {
  display: block;
}
.spinner-container .spinner-loader {
  position: relative;
  margin: 0 auto;
  width: 40px;
}
.spinner-container .spinner-loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.spinner-container .circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  stroke-width: 5px;
}
.spinner-container .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  100%, 0% {
    stroke: #00aeef;
  }
  40% {
    stroke: #db51d2;
  }
  66% {
    stroke: #00cc67;
  }
  80%, 90% {
    stroke: #ffb400;
  }
}

