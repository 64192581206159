// slide out transition used to remove a smallish list item
// note that it cannot be applied to a table row (the table won't force the element's
// size), so I also added the selector for the first element inside of the row so that
// we could use it inside of a react transition group applied to the table
.remove-item-leave,
tr.remove-item-leave > td :first-child {
  transition: max-height 0.3s linear, padding 0.3s linear;
  overflow: hidden;
  max-height: 100px;
  overflow: hidden;
  &.remove-item-leave-active,
  &.remove-item-leave-active > td :first-child {
    max-height: 0;
    padding: 0;
    margin: 0;
  }
}
