// additional styles for the react-select control

// stock styles
// this doesn't work, it tries to process the import as a regular CSS import instead of inlining it
//@import '{}/node_modules/react-select/dist/react-select.css';

.Select-menu {
  font-size: 10px;
}

.Select.is-disabled,
.Select.is-disabled .Select-control {
  cursor: not-allowed;
  background-color: #e6e6e6 !important;
}

.Select-menu-outer {
  // put it on top of the Bootstrap input groups
  // (make it important as the react select styles are getting imported last)
  z-index: 10 !important;
}
