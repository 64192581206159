.menu-expanded {
  .timesheet-mobile-dashboard {
    bottom: 0;
    z-index: 1001;
    left: 180px;
    right: 0;
    transition: width 0.4s;
  }
}

.timesheet-mobile-dashboard {
  position: fixed;
  bottom: 0;
  //@include left-menu-space();
  z-index: 10000;
  left: 0;
  right: 0;
  background-color: #27aae1;
  clear: both;
  transform: translateY(120%);
  // there is no transition on opacity, but having this ensure we don't see the dashboard
  // during slide-up transitions
  opacity: 0;
  transition: transform 0.5s;

  &.expanded {
    opacity: 1;
    transform: translateY(0);
  }

  .actions-sub-menu {
    // use abs pos so we don't have to worry about lining it up perfect
    // with the action button
    position: absolute;
    z-index: -1;
    left: 80px;
    right: 0;
    height: 100%;
    border: 2px solid #fd8940;
  }

  .actions-button {
    width: 100%;
    padding-top: 8px;
    text-align: center;
    color: white;
    clear: both;
  }

  .actions-sub-button {
    @include control-button;
    text-align: center;
    font-size: 12px;
    margin: 0 0 8px 0;
    width: 80%;
    height: 35px;
    padding-top: 10px;

    &.timesheet--submit {
      margin-bottom: 25px;
    }

    i {
      padding-right: 5px;
    }

    label {
      margin-bottom: 0 !important;
    }
  }

  .close {
    float: right;
    font-size: 32px;
    font-weight: bold;
    line-height: 1;
    color: #ffffff;
    text-shadow: none;
    opacity: 1;
    padding: 0 0 0 10px;
  }
}
