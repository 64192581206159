// Timesheet buttons (generic rules)
// See also the mobile dashboard file which has some more specific rules for those buttons

.btn.desktop-control {
  @include control-button;
}

.timesheet-desktop-controls {
  .btn[disabled] {
    background-color: #dddddd !important;
    cursor: not-allowed;

    html.can-touch &:active,
    html:not(.can-touch) &:hover {
      background-color: #dddddd !important;
      cursor: not-allowed;
    }
  }
}

.timesheet--submit,
.timesheet--approve,
.timesheet--recall {
  margin-top: 11px;

  &.desktop-control {
    float: right;
  }
}

.timesheet--submit {
  background-color: #3cb878 !important;
  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: #2ba265 !important;
  }
}

.timesheet--recall {
  background-color: #fb5d5d !important;
  html.can-touch &:active,
  html:not(.can-touch) &:hover {
    background-color: #f91a1a !important;
  }
}
