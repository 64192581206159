// Style for the autocomplete widget
// Used for project selection and timesheet cell entry

.autocomplete-filter {
}

.autocomplete-options {
  margin-top: 0;
  margin-bottom: 10px;
  list-style-type: none;
  padding-left: 5px;
  position: absolute;
  width: 100%;
  z-index: 9999;
  background-color: white;

  .highlighted {
    background-color: blue;
  }

  li {
    list-style: none;
    cursor: pointer;
  }
}

.autocomplete-display {
  cursor: pointer;
  // position: relative;
  // padding-right: 12px;
  // border: 1px solid black;

  &.not-selected {
    font-style: italic;
  }

  i {
    // position: absolute;
    // right: 0;
    // padding: 2px;
    // width: 12px;
  }
}

.autocomplete .form-control {
  // we need to allow a bit of room for the borders, otherwise that control slides down a row on mobiles
  // (iOS + Samsung)
  width: 'calc(100% - 2px)';
}
