@import '_navbarStyle';
@import '_expanded';
@import '_navLogo';
@import '_responsive';

.logo-toggle {
  background-color: #2b2d34 !important;
}

@media screen and (max-width: 800px) and (orientation: landscape) {
  .menu-control {
    display: none;
  }
}
