// larger spinner for loading screens

$bgcolor: rgba(204, 204, 204, 0.9);
$bgcolor-in: fade_out($bgcolor, 0.6);
$bgcolor-out: fade_out($bgcolor, 0.4);
$green: #00cc67;
$blue: #00aeef;
$purple: #db51d2;
$yellow: #ffb400;
$white: #eee;

// scaling... any units
$width: 40px;

// default spinner will stick to the first 100 px of the container it's put in
// the container must have relative positioning to provide a positioning context
.spinner-container {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  position: absolute;
  background: rgba(0, 0, 0, 0.15);
  // don't set it too high so it does not end up on top of dialogs
  z-index: 5;

  &.kpi-loading {
    position: relative;
    height: 54px;
    width: 100%;
    background-color: transparent !important;
  }

  .spinner-inner {
    position: absolute;
    padding: 10px;
    left: 50%;
    top: 40%;
    transform: translate3d(-50%, -50%, 0);
  }

  @keyframes circles-loader {
    0% {
      transform: rotate(-720deg);
    }
    50% {
      transform: rotate(720deg);
    }
  }

  // css-spinners
  .circles-loader {
    position: relative;
    text-indent: -9999px;
    display: inline-block;
    width: 16px;
    height: 16px;
    background: #03a9f4;
    border-radius: 100%;
    animation: circles-loader 3s infinite ease-in-out;
    transform-origin: 50% 82%;

    &::before {
      background: $bgcolor-in;
      border-radius: 100%;
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      top: 18.75px;
      left: -10.82532px;
    }
    &::after {
      background: $bgcolor-out;
      border-radius: 100%;
      content: '';
      position: absolute;
      width: 25px;
      height: 25px;
      top: 18.75px;
      left: 10.82532px;
    }
  }

  .spinner-text {
    display: block;
  }

  .spinner-loader {
    position: relative;
    margin: 0 auto;
    width: $width;
    &:before {
      content: '';
      display: block;
      padding-top: 100%;
    }
  }

  .circular {
    animation: rotate 2s linear infinite;
    height: 100%;
    transform-origin: center center;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    stroke-width: 5px;
  }

  .path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
    stroke-linecap: round;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  @keyframes color {
    100%,
    0% {
      stroke: $blue;
    }
    40% {
      stroke: $purple;
    }
    66% {
      stroke: $green;
    }
    80%,
    90% {
      stroke: $yellow;
    }
  }
}
