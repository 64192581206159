.uploader {
  width: 100%;
  min-height: 300px;
  background: slategray;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem 0;
  cursor: pointer;
}

.uploader.uploader--selected {
  min-height: 100px;
  background-color: #dff0d8;
  border-color: #d0e9c6;
}

.uploader p {
  font-size: 4rem;
}

.uploader p + p {
  font-size: 2rem;
}

.uploader--reject {
  background: red;
}

.uploader--active {
  background: lightgreen;
}
