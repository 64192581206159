label.treehouse-checkbox {
  flex-flow: row nowrap;
  font-weight: normal;
  input[type='checkbox'] {
    display: none;
  }

  div {
    flex-grow: 0;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    display: inline-block;
    border: 1px solid #bfbfbf;
    background: #fff;
    text-align: center;
    line-height: 14px;
    margin-right: 5px;
    border-radius: 1px;
    cursor: pointer;
  }

  // put checkbox on the right?
  &.left-label div {
    order: 2;
  }

  span {
    flex-grow: 1;
    padding: 0 5px;
  }

  i {
    font-size: 14px;
    color: #7b7272;
    opacity: 0;
    float: none;
    margin: 0;
  }
  html.can-touch &:active div,
  html:not(.can-touch) &:hover div {
    background: #fbfbfb;
  }
  &.checked {
    i {
      opacity: 1;
      font-size: 14px;
    }
    div {
      background: #fbfbfb;
    }
  }
}
