// used in the datetimepicker for example, these are only displayed on hover
// and they allow for adjusting the values of a control in small increments.
html.can-touch .input-group:active .updownbuttons,
html:not(.can-touch) .input-group:hover .updownbuttons {
  opacity: 1;
}

.updownbuttons {
  border: none;
  padding: 0 2px;
  background-color: transparent;
  opacity: 0;
  transition: opacity 0.2s;
  position: absolute;
  z-index: 10;
  top: 0;
  bottom: 0;
  right: 5px;
  width: 16px;

  // for the ones that also have an additional addon in the input group
  // unfortunately the size is hardcoded for now
  .has-addon & {
    right: 45px;
  }

  .btn-up,
  .btn-down {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
  }

  .btn-up {
    //top: "calc(50% - 18px)";
    top: 5px;
  }
  .btn-down {
    //bottom: "calc(50% - 18px)";
    bottom: 5px;
  }
}
