header {
  &.search-bar {
    margin: 10px;

    img {
      height: 37px;
    }
  }

  &.app-header {
    margin: 10px;

    img {
      height: 37px;
    }
  }

  h4 {
    display: inline-block;

    @media screen and (max-width: 550px) {
      display: none;
    }
  }

  .settings-button-menu {
    right: 9px !important;
    width: 159px;

    li {
      padding-top: 13px;
      padding-bottom: 13px;
    }
  }

  .settings-button-container {
    display: inline-block;
    button {
      border: none;
      background-color: transparent;
    }
    // make this open to the left
    .dropdown-menu {
      right: 0;
      left: auto;
    }

    i {
      margin-left: 7px;
    }
  }
}
